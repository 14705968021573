import React from "react";
import NavBar from "../Components/NavBar/NavBar";
import HeaderMain from "../Components/HeaderMain/HeaderMain";
import Benefits from "../Components/Benefits/Benefits";
import ProductsLists from "../Components/ProductsList/ProductsLists";
import Footer from "../Components/Footer/Footer";
import whatsapplogo from "../Assets/whatsapp.png";
import "./Home.css";

const Home = () => {
  return (
    <div>
      <NavBar />
      <HeaderMain />
      <ProductsLists />
      <Benefits />
      <Footer />
      <div className="whatsapp-logo">
        <a href="https://wa.me/923139520225">
          <img
            src={whatsapplogo}
            alt="WhatsApp Logo"
            style={{ width: "60px", height: "60px" }}
            className="whatsapp-img"
          />
        </a>
      </div>
    </div>
  );
};

export default Home;
