import React from 'react'
import BnbAcneKit from "../../Assets/Products/BNB acne kit.png";
import BnbRiceKit from "../../Assets/Products/BNB rice kit.jpg";
import CreaveHydrating from "../../Assets/Products/CeraVe hydrating cleanser.webp";
import CreaveFoaming from "../../Assets/Products/CeraVe foaming cleanser.jpg";
import OrdinarySerum from "../../Assets/Products/Ordinary serum niacinamide.jpg";
import FVFoundation from "../../Assets/Products/Fv foundation.webp";
import BioRiceGell from "../../Assets/Products/Bioaqua rice gel.webp";
import BioPeachGell from "../../Assets/Products/Bioaqua peach gel.jpg";
import SunisaFounation from "../../Assets/Products/Sunisa foundation.webp";
import KeratinMask from "../../Assets/Products/Keratin hair mask.png";
import HairOil from "../../Assets/Products/Hair food oil.webp";
import Sunblock from "../../Assets/Products/Rivaj sunblock.jpg";
import T9Trimmer from "../../Assets/Products/T9 trimmer.png";
import GreenTea from "../../Assets/Products/Green tea mask.png";
import HairRemover from "../../Assets/Products/Ecrin hair removel spray.jpg";
import Lipstick from "../../Assets/Products/5 in 1 lipsticks.webp";
import WomenTrimmer from "../../Assets/Products/2 in 1 women trimmer.jpg";
import NightCreamImage from "../../Assets/Products/night cream.jpg";



const Cosmetics = () => {
    const products = [
        {
            id: 1,
            name: "Bnb Acne Kit",
            price: "3200",
            image: BnbAcneKit,
        },
        {
            id: 2,
            name: "Bnb Rice Kit",
            price: "2000",
            image: BnbRiceKit,
        },
        {
            id: 3,
            name: "Cerave Hydrating",
            price: "2700",
            image: CreaveHydrating,
        },
        {
            id: 4,
            name: "Cerave Foaming",
            price: "2700",
            image: CreaveFoaming,
        },
        {
            id: 5,
            name: "Ordinary Serum",
            price: "1650",
            image: OrdinarySerum,
        },
        {
            id: 6,
            name: "Fv Foundation",
            price: "1200",
            image: FVFoundation,
        },
        {
            id: 7,
            name: "BioAqua Rice Gel",
            price: "1450",
            image: BioRiceGell,
        },
        {
            id: 8,
            name: "Bioaqua Peach Gel",
            price: "1100",
            image: BioPeachGell,
        },
        {
            id: 9,
            name: "Sunisa Foundation",
            price: "1100",
            image: SunisaFounation,
        },
        {
            id: 10,
            name: "Keratin Hair Mask",
            price: "1100",
            image: KeratinMask,
        },
        {
            id: 11,
            name: "Hair Food Oil",
            price: "1200",
            image: HairOil,
        },
        {
            id: 12,
            name: "Rivaj Sunblock",
            price: "1300",
            image: Sunblock,
        },
        {
            id: 13,
            name: "T9 Trimmer",
            price: "1350",
            image: T9Trimmer,
        },
        {
            id: 14,
            name: "Green Tea Mask",
            price: "900",
            image: GreenTea,
        },
        {
            id: 15,
            name: "Ecrin Hair Removal",
            price: "1200",
            image: HairRemover,
        },
        {
            id: 16,
            name: "5-in-1 Lipstick",
            price: "800",
            image: Lipstick,
        },
        {
            id: 17,
            name: "2-in-1 Women Trimmer",
            price: "1400",
            image: WomenTrimmer,
        },
        {
            id: 18,
            name: "Night Cream",
            price: "1100",
            image: NightCreamImage,
        }
    ];
    return (
        <div className="container d-flex justify-content-center p-0 ">
            <div
                className="row justify-content-center align-items-center w-100"
                style={{
                    backgroundColor: "#f5f5f5",
                    paddingTop: "5px",
                }}
            >
                {products.map((item, id) => (
                    <div
                        key={id}
                        className="p-0 col-lg-3 col-md-3 col-sm-6 col-6 d-flex align-items-stretch "
                        onClick={() =>
                        (window.location.href = `/products/${item.name
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`)
                        }
                        style={{ cursor: "pointer" }}
                    >
                        <div className="item">
                            <img src={item.image} alt="product Img" />
                            <h5 style={{ textAlign: "center" }}>{item.name}</h5>
                            <p className="price">RS: {item.price}/-</p>
                            <button>
                                <a href="https://wa.me/923139520225">
                                    <i
                                        style={{ fontSize: "25px" }}
                                        class="fa-brands fa-whatsapp"
                                    ></i>
                                </a>
                                <a href="https://wa.me/923139520225">Order Now</a>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Cosmetics