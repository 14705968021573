import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Productdetail from "./Components/ProductDetail/ProductDetail";
import BnbAcneKit from "../src/Assets/Products/BNB acne kit.png";
import BnbRiceKit from "../src/Assets/Products/BNB rice kit.jpg";
import CreaveHydrating from "../src/Assets/Products/CeraVe hydrating cleanser.webp";
import CreaveFoaming from "../src/Assets/Products/CeraVe foaming cleanser.jpg";
import OrdinarySerum from "../src/Assets/Products/Ordinary serum niacinamide.jpg";
import FVFoundation from "../src/Assets/Products/Fv foundation.webp";
import BioRiceGell from "../src/Assets/Products/Bioaqua rice gel.webp";
import BioPeachGell from "../src/Assets/Products/Bioaqua peach gel.jpg";
import SunisaFounation from "../src/Assets/Products/Sunisa foundation.webp";
import KeratinMask from "../src/Assets/Products/Keratin hair mask.png";
import HairOil from "../src/Assets/Products/Hair food oil.webp";
import Sunblock from "../src/Assets/Products/Rivaj sunblock.jpg";
import T9Trimmer from "../src/Assets/Products/T9 trimmer.png";
import GreenTea from "../src/Assets/Products/Green tea mask.png";
import HairRemover from "../src/Assets/Products/Ecrin hair removel spray.jpg";
import Lipstick from "../src/Assets/Products/5 in 1 lipsticks.webp";
import WomenTrimmer from "../src/Assets/Products/2 in 1 women trimmer.jpg";
import MiniWashingMachineImage from "../src/Assets/Products/mini washing machine.jpg";
import MiniBodyMassagerImage from "../src/Assets/Products/mini body messager.jpg";
import MiniJuicerBlenderImage from "../src/Assets/Products/mini juicer.jpg";
import MiniSewingMachineImage from "../src/Assets/Products/mini sewing.jpg";
import PowerBank20000mAhImage from "../src/Assets/Products/power bank.jpg";
import TemperatureWaterBottleImage from "../src/Assets/Products/water bottle.jpg";
import BookTabImage from "../src/Assets/Products/book and tabs.jpg";
import NightCreamImage from "../src/Assets/Products/night cream.jpg";

const products = [
  {
    name: "Keratin hair mask",
    price: "1100",
    image: KeratinMask,
    description: {
      Material: "Cream",
      ProductType: "Brazilian Nut Keratin Hair Mask",
      ProductDetails:
        "Keratin Hair Masks Are A Popular Treatment For Damaged Dry Or Frizzy Hair, Restores Hair Health, Smooths Frizz, Strengthens Hair, Improves Manageability, Repairs Damaged Hair, Improves Elasticity, Enhances Shine",
      Color: "Brown",
      PackageIncludes: "1 x Hair mask",
      Weight: "500 Ml",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ58600021MNSTP",
    },
  },
  {
    name: "Hair food oil",
    price: "1200",
    image: HairOil,
    description: {
      Material: "Oil",
      HairTypes: "All Hair Types",
      Functions: ["Nourish", "Hair Growth", "Anti-Dandruff", "Shine"],
      PackageIncludes: "1 x Hair Oil",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ60320009MNSRPS",
    },
  },
  {
    name: "BioAqua Peach Gel",
    price: "1100",
    image: BioPeachGell,
    description: {
      Material: "Scrub",
      ProductType: "Dead Skin Remover Scrub",
      ProductDetails:
        "Scientifically Combined Concentration, Gentle Cleansing of Waste Aging Keratin, Smooth and Soft Skin",
      fixTheProblem: [
        "rough muscles",
        "back acne",
        "black orange peel on the tip of the elbow",
      ],
      PackageIncludes: "1 x Scrub",
      Weight: "140 g",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ53900036BTBZR",
    },
  },
  {
    name: "T9 Trimmer",
    price: "1350",
    image: T9Trimmer,
    description: {
      Material: "ABS Alloy",
      ProductFeatures: [
        "Sharp Blades And Cutting",
        "Carbon Steel Cutter",
        "Cutting Sharp And Durable",
        "4 Attachment Guard",
        "Charging Cable",
      ],
      BatteryTime: "30 - 45 Minutes",
      PackageIncludes:
        "1 x Trimming Machine, 4 x Limit Comb, 1 x Cleaning Brush",
      Note: "Please ensure to follow the instructions provided in the user manual for proper usage and safety precautions",
      ProductCode: "MZ49200001AFTRD",
    },
  },
  {
    name: "Rivaj Sunblock",
    price: "1300",
    image: Sunblock,
    description: {
      WaterResistant: true,
      netWeight: "120ml",
      Functions: [
        "Anti-dark Spots",
        "Anti Darkening",
        "Multipurpose Product For Protecting Skin From UV-A and B Rays",
      ],
      ForSkinTypes: "All Skin Types",
      Disclaimer:
        "Products available for purchase are not intended to diagnose, treat, reduce, prevent, or cure any disease or health condition. If you are not sure of the possible sensitivity, always do a patch test before use.",
      ProductCode: "MZ23900051GLMRS",
    },
  },
  {
    name: "Bnb Acne Kit",
    price: "3200",
    image: BnbAcneKit,
    description: {
      Material: "Cream",
      Functions: [
        "Targets Different Types Of Acne",
        "Reduces Excess Oil",
        "Promotes Clearer Skin",
        "Long Lasting Results",
      ],
      suitableForSkinTypes: "All Skin Types",
      PackageIncludes: "1 x Facial Kit",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ60320016MNSRPS",
    },
  },
  {
    name: "Sunisa Foundation",
    price: "1100",
    image: SunisaFounation,
    description: {
      type: "BB Cream Foundation",
      features: [
        "Air Cushion BB Cream Is A Combination Of Foundation, BB Cream And Concealer",
        "Provides A Flawless And Airbrushed Look",
        "Moisturizing",
        "Oil Control",
        "Long Lasting",
      ],
      forSkinTones: "All Skin Tones",
      Disclaimer:
        "Before using a new cosmetic product, try a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read warning labels and directions on the package for using any cosmetics.",
      ProductCode: "MZ18700452RFAAC",
    },
  },
  {
    name: "Bnb Rice Kit",
    price: "2000",
    image: BnbRiceKit,
    description: {
      Material: "Cream",
      ProductType: "Rice Whitening And Glowing Facial Kit",
      ProductDetails:
        "Gives A Natural Glow To Your Skin, Improve Complexion, Brightening Glow Kit Rice Scrub Face Wash + Mask (3 in 1)",
      PackageIncludes: "1 x Facial Kit",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ53900121BTBZR",
    },
  },
  {
    name: "Green Tea Mask",
    price: "900",
    image: GreenTea,
    description: {
      ForSkinTypes: "All Skin Types",
      Functions: [
        "Moisturizing And Cleansing",
        "For Soft And Smooth Skin",
        "Anti Acne And Oil Control",
      ],
      Weight: "40g",
      PackageIncludes: "1 x Stick",
      Disclaimer:
        "Products available for purchase are not intended to diagnose, treat, reduce, prevent, or cure any disease or health condition. If you are not sure of the possible sensitivity, always do a patch test before use.",
      ProductCode: "MZ53900009BTBZR",
    },
  },
  {
    name: "2-in-1 Women Trimmer",
    price: "1400",
    image: WomenTrimmer,
    description: {
      Material: ["ABS Plastic", "Metal"],
      ProductDetails:
        "Remove Unwanted Facial Hair, Perfect For Eyebrows, Leaving Your Clean And Flawless, Safe And Gentle, Durable And Long Lasting, Easy To Use, Smooth Hair Free Skin",
      PackageIncludes: "1 x Trimmer",
      Note: "Please ensure to follow the instructions provided in the user manual for proper usage and safety precautions",
      ProductCode: "MZ49200029AFTRD",
    },
  },
  {
    name: "5-in-1 Lipstick",
    price: "800",
    image: Lipstick,
    description: {
      Material: "Cream",
      ProductType: "5 In 1 Lipsticks",
      ProductDetails:
        "Highly Pigmented, Soft And Smooth Texture Gives A Perfect Application, Rich Color Hydrating Lipsticks, Beautiful Velvety Texture",
      shades: ["1", "2"],
      PackageIncludes: "1 x Lipsticks",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ72600012BSMCS",
    },
  },
  {
    name: "Ecrin Hair Removal",
    price: "1200",
    image: HairRemover,
    description: {
      Material: "Spray",
      ProductType: "Hair Removal Spray",
      ProductDetails:
        "Remove Unwanted Hair, Leaving Your Skin Clean And Flawless, Safe And Gentle, Easy To Use, Smooth Hair Free Skin",
      Weight: "150 Ml",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ18700878RFAAC",
    },
  },
  {
    name: "Fv Foundation",
    price: "1200",
    image: FVFoundation,
    description: {
      Material: "Liquid",
      ProductType: "Matte Coverage Foundation",
      ProductDetails:
        "Matte And Poreless Finish, High Quality Smooth Application, Provides Long Lasting Full Coverage, LightWeight And Breathable",
      shade: "Natural",
      PackageIncludes: "1 x Foundation",
      Weight: "30 g",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ2600356ARCC",
    },
  },
  {
    name: "Ordinary Serum",
    price: "1650",
    image: OrdinarySerum,
    description: {
      item: "Serum",
      size: "30 ML",
      Functions: [
        "Combat Blemishes Effectively With The Ordinary Niacinamide 10% + Zinc 1% Formula",
        "Harness The Power Of Niacinamide And Zinc For Clearer, Balanced Skin",
      ],
      ProductCode: "MZ18700482RFAAC",
    },
  },
  {
    name: "BioAqua Rice Gel",
    price: "1450",
    image: BioRiceGell,
    description: {
      ForSkinTypes: [
        "Normal",
        "Oily",
        "Dry",
        "Combination",
        "Sensitive",
        "Acne-Prone",
      ],
      PackageIncludes: "1 x Exfoliating Gel",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ60320002MNSRPS",
    },
  },
  {
    name: "Cerave Foaming",
    price: "2700",
    image: CreaveFoaming,
    description: {
      Material: "Cleanser",
      ProductType: "Whitening And Glowing Facial Kit",
      ProductDetails:
        "Blend Of Fruit Extracts And Oils, Gives A Natural Glow To Your Skin, Improve Complexion, Whitening Face Wash, Whitening Facial Cleanser, Whitening Facial Scrub, Whitening Facial Polish, Whitening",
      PackageIncludes: "1 x Facial Kit",
      Weight: "236 ml",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ18700855RFAAC",
    },
  },
  {
    name: "Cerave Hydrating",
    price: "2700",
    image: CreaveHydrating,
    description: {
      Material: "Liquid",
      ProductType: "Hydrating Facial Cleanser",
      ProductDetails:
        "Moisturize Skin, Refines Skin Texture, Deeply Nourishing, Helps Brighten Skin Tone",
      PackageIncludes: "1 x Facial Cleanser",
      Weight: "237 Ml",
      Disclaimer:
        "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ58600039MNSTP",
    },
  },
  {
    name: "Mini Washing Machine",
    price: "2000",
    image: MiniWashingMachineImage, // Ensure to replace 'MiniWashingMachineImage' with the actual image variable or path
    description: {
      Material: "ABS Plastic",
      ProductFeatures: "Power supply: USB, Input Voltage: DC 5V-10W, Output voltage: DC 12V-24W, Rotation speed: 200 RPM, Output power: 6W",
      Dimensions: "10 cm x 5.5 cm",
      PackageIncludes: "1 x Mini Washing Machine Turbine Washer",
      Note: "Please ensure to follow the instructions provided in the user manual for proper usage and safety precautions.",
      ProductCode: "MZ49200569AFTRD",
    },
  },
  {
    name: "Mini Body Massager",
    price: "1200",
    image: MiniBodyMassagerImage, // Ensure to replace 'MiniBodyMassagerImage' with the actual image variable or path
    description: {
      Material: "ABS Plastic",
      Model: "BLD-320",
      ProductFeatures: "It Contains A Smart Chip And An Automatic Timer To Turn Off Automatically. 8 Different Massage Modes Are Given So You Can Adjust The Variations According To The Need. The Main Unit Of The Mini Massage Cushion Is 7.8 Cm In Diameter And 1.7 Cm In Thickness. Best For Home Travel",
      VoltageWatts: "2V",
      PackageIncludes: "1 x Butterfly Mini Massager Gun",
      Note: "Please ensure to follow the instructions provided in the user manual for proper usage and safety precautions.",
      ProductCode: "MZ49200193AFTRD",
    },
  },
  {
    name: "Mini Juicer Blender",
    price: "2200",
    image: MiniJuicerBlenderImage, // Ensure to replace 'MiniJuicerBlenderImage' with the actual image variable or path
    description: {
      Material: "ABS Plastic",
      NumberOfPieces: "1 Pc",
      PackageIncludes: "1 x Juicer Blender",
      Note: "There might be an error of 1-3 cm due to manual measurement, and slight color differences may occur as a result of varying lighting and monitor effects.",
      ProductCode: "MZ51120042AFTS",
    },
  },
  {
    name: "Mini Sewing Machine",
    price: "2000",
    image: MiniSewingMachineImage, // Ensure to replace 'MiniSewingMachineImage' with the actual image variable or path
    description: {
      Material: "ABS Plastic",
      ProductFeatures: "Speed Control Handy Sewing Machine, Suitable For All Types Of Fabrics",
      VoltageWatts: "6 V",
      PackageIncludes: "1 x Sewing Machine",
      Note: "Please ensure to follow the instructions provided in the user manual for proper usage and safety precautions.",
      ProductCode: "MZ4900077ZLMS",
    },
  },
  {
    name: "Power Bank 20000mAh",
    price: "3500",
    image: PowerBank20000mAhImage, // Ensure to replace 'PowerBank20000mAhImage' with the actual image variable or path
    description: {
      Material: "Plastic",
      Color: "Random Color",
      PackageIncludes: "1 x Power Bank",
      Note: "Please ensure to follow the instructions provided in the user manual for proper usage and safety precautions.",
      ProductCode: "MZ63320016GSHE",
    },
  },
  {
    name: "Temperature Water Bottle",
    price: "1900",
    image: TemperatureWaterBottleImage, // Ensure to replace 'TemperatureWaterBottleImage' with the actual image variable or path
    description: {
      Material: "Stainless Steel",
      NumberOfPieces: "1 Pc",
      ProductFeature: "Eco-Friendly Materials For Sustainability",
      PackageIncludes: "1 x Water Bottle",
      Note: "There might be an error of 1-3 cm due to manual measurement, and slight color differences may occur as a result of varying lighting and monitor effects.",
      ProductCode: "MZ116020005ADSE",
    },
  },
  {
    name: "4 Book + 8.5 Inch Tab",
    price: "1600",
    image: BookTabImage, // Ensure to replace 'BookTabImage' with the actual image variable or path
    description: {
      Material: "Plastic",
      NumberOfPieces: "15 Pcs",
      PackageIncludes: "1 x LCD Writing Tablet, 4 x Reusable Magic Books, 10 Refills Magic Pen",
      Note: "There might be an error of 1-3 cm due to manual measurement, and slight color differences may occur as a result of varying lighting and monitor effects.",
      ProductCode: "MZ21820001AQTS",
    },
  },
  {
    name: "Night Cream",
    price: "1100",
    image: NightCreamImage, // Ensure to replace 'NightCreamImage' with the actual image variable or path
    description: {
      PackageIncludes: "1 x Night Cream",
      Disclaimer: "Before using a new cosmetic product, conduct a test on a small area of your skin. If you have a specific allergy or sensitivity, check the ingredients to avoid a reaction. Always read the warning labels and directions on the package for using any cosmetic products.",
      ProductCode: "MZ60320022MNSRPS",
    },
  }
  
  
  
  
  
  
];

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          {products.map((product, index) => {
            const path = `/products/${product.name
              .replace(/\s+/g, "-")
              .toLowerCase()}`;
            return (
              <Route
                key={index}
                path={path}
                element={<Productdetail product={product} />}
              />
            );
          })}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
