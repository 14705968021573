import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer__about">
                <div className="footer__logo">
                  <a
                    href="https://wa.me/923139520225"
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                    }}
                  >
                    <h3>AR Beauty Store</h3>
                  </a>
                </div>
                <p>
                  Discover premium beauty products to enhance your natural
                  radiance effortlessly.
                </p>
              </div>
            </div>
            <div className="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
              <div className="footer__widget">
                <h6>Shopping</h6>
                <ul style={{ padding: "0" }}>
                  <li>
                    <a href="https://wa.me/923139520225">Beauty Products</a>
                  </li>
                  <li>
                    <a href="https://wa.me/923139520225">Whitening Cream</a>
                  </li>
                  <li>
                    <a href="https://wa.me/923139520225">Facial Kits</a>
                  </li>
                  <li>
                    <a href="https://wa.me/923139520225">Trimmer</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer__widget">
                <h6>Shopping</h6>
                <ul style={{ padding: "0" }}>
                  <li>
                    <a href="https://wa.me/923139520225">Contact Us</a>
                  </li>
                  <li>
                    <a href="https://wa.me/923139520225">Payment Methods</a>
                  </li>
                  <li>
                    <a href="https://wa.me/923139520225">Delivary</a>
                  </li>
                  <li>
                    <a href="https://wa.me/923139520225">Return & Exchanges</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 offset-lg-1 col-md-6 col-sm-6">
              <div className="footer__widget">
                <h6>NewLetter</h6>
                <div className="footer__newslatter">
                  <p>
                    Be the first to know about new arrivals, look books, sales &
                    promos!
                  </p>
                  <form action="https://wa.me/923139520225">
                    <input type="text" placeholder="Your email" />
                    <button type="submit">
                      <span className="icon_mail_alt"></span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="footer__copyright__text">
                <p>
                  Copyright © 2024 All rights reserved | This Website is made
                  with <i className="fa fa-heart-o" aria-hidden="true"></i> by{" "}
                  <a href="https://blinkdevsolutions.com" target="_blank">
                    Blink Dev Solutions
                  </a>
                </p>
                {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
