// import React from "react";
// import "./HeaderMain.css";

// const HeaderMain = () => {
//   return <div className="headerParent"></div>;
// };

// export default HeaderMain;

import React from "react";
import headerBackColor2 from "../../Assets/headerBackColor2.png";
import "./HeaderMain.css";

const HeaderMain = () => {
  return (
    <>
      <div className="headerParent">
        <img src={headerBackColor2} alt="header" />
      </div>
    </>
  );
};

export default HeaderMain;
