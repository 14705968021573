import React, { useState } from "react";
import "./ProductsList.css";
import Cosmetics from "../Cosmetics/Cosmetics";
import Gadgets from "../Gadgets/Gadgets";

const ProductsLists = () => {
  const [activeTab, setActiveTab] = useState(1);
  const tabs = [
    {
      id: 1,
      name: "Cosmetics",
    },
    {
      id: 2,
      name:"Gadgets"
    }
  ]

  return (
    <>
      <div classNameName=" p-0">
        <div className="section-title">
          <h2>Products</h2>
          <p>Unleash Your Inner Beauty</p>
        </div>
        <div className="tabsButtonsContainer">
          {
            tabs.map((item , id) =>{
              return(
                <button key= {id} className={`tabsButtons ${activeTab === item.id ? "activeButton" : ""}`} onClick={() => setActiveTab(item.id)}>{item.name}</button>
              )
            })
          }
        </div>
        <br/>

        {activeTab === 1 && <Cosmetics/>}
        {activeTab === 2 && <Gadgets/>}
        {/* <div className="container d-flex justify-content-center p-0 ">
          <div
            className="row justify-content-center align-items-center w-100"
            style={{
              backgroundColor: "#f5f5f5",
              paddingTop: "5px",
            }}
          >
            {products.map((item, id) => (
              <div
                key={id}
                className="p-0 col-lg-3 col-md-3 col-sm-6 col-6 d-flex align-items-stretch "
                onClick={() =>
                  (window.location.href = `/products/${item.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`)
                }
                style={{ cursor: "pointer" }}
              >
                <div className="item">
                  <img src={item.image} alt="product Img" />
                  <h5 style={{ textAlign: "center" }}>{item.name}</h5>
                  <p className="price">RS: {item.price}/-</p>
                  <button>
                    <a href="https://wa.me/923139520225">
                      <i
                        style={{ fontSize: "25px" }}
                        class="fa-brands fa-whatsapp"
                      ></i>
                    </a>
                    <a href="https://wa.me/923139520225">Order Now</a>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ProductsLists;
