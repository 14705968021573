import React from "react";
import Navbar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import "./ProductDetail.css";
import ProductsLists from "../ProductsList/ProductsLists";
import Benefits from "../Benefits/Benefits";
import whatsapplogo from "../../Assets/whatsapp.png";

const ProductDetail = ({ product }) => {
  const renderDescription = (description) => {
    return Object.entries(description).map(([key, value]) => {
      // Handle array values by mapping through them
      if (Array.isArray(value)) {
        return (
          <div key={key}>
            <span style={{ color: "#e7796a", fontSize: "20px" }}>
              <b>{key}:</b>
            </span>{" "}
            <ul>
              {value.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        );
      }
      // Handle non-array values
      return (
        <div key={key}>
          <p>
            <span style={{ color: "#e7796a", fontSize: "20px" }}>
              <b>{key}:</b>
            </span>{" "}
            {value}
          </p>
        </div>
      );
    });
  };

  return (
    <>
      <Navbar />
      {/* <div className="container" style={{ marginTop: "50px" }}>
        <a
          href="/"
          style={{
            color: "black",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            margin: 0,
          }}
        >
          <i
            class="fa-solid fa-arrow-left"
            style={{ fontSize: "25px", paddingLeft: "20px" }}
          ></i>
          Back
        </a>
      </div> */}
      <div className="section-title">
        <h2>Details</h2>
      </div>
      <div className="container productParent">
        <div className="sectionImg">
          <img alt="Product img" src={product.image} />
        </div>
        <div className="sectionDetail">
          <h2 style={{ textAlign: "center", color: " " }}> {product.name}</h2>
          <h4 style={{ color: "#c74156", fontWeight: "500" }}>
            {" "}
            RS:{product.price}/-
          </h4>
          <div>{renderDescription(product.description)}</div>
          {/* <br /> */}
          <button>
            <a href="https://wa.me/923139520225">
              <i style={{ fontSize: "25px" }} class="fa-brands fa-whatsapp"></i>
            </a>
            <a href="https://wa.me/923139520225">Order Now</a>
          </button>
        </div>
      </div>

      <ProductsLists />
      <Benefits />
      <Footer />
      <div className="whatsapp-logo">
        <a href="https://wa.me/923139520225">
          <img
            src={whatsapplogo}
            alt="WhatsApp Logo"
            style={{ width: "60px", height: "60px" }}
            className="whatsapp-img"
          />
        </a>
      </div>
    </>
  );
};

export default ProductDetail;
