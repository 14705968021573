import React from 'react'
import BnbAcneKit from "../../Assets/Products/BNB acne kit.png";
import BnbRiceKit from "../../Assets/Products/BNB rice kit.jpg";
import CreaveHydrating from "../../Assets/Products/CeraVe hydrating cleanser.webp";
import CreaveFoaming from "../../Assets/Products/CeraVe foaming cleanser.jpg";
import OrdinarySerum from "../../Assets/Products/Ordinary serum niacinamide.jpg";
import FVFoundation from "../../Assets/Products/Fv foundation.webp";
import BioRiceGell from "../../Assets/Products/Bioaqua rice gel.webp";
import BioPeachGell from "../../Assets/Products/Bioaqua peach gel.jpg";
import SunisaFounation from "../../Assets/Products/Sunisa foundation.webp";
import KeratinMask from "../../Assets/Products/Keratin hair mask.png";
import HairOil from "../../Assets/Products/Hair food oil.webp";
import Sunblock from "../../Assets/Products/Rivaj sunblock.jpg";
import T9Trimmer from "../../Assets/Products/T9 trimmer.png";
import GreenTea from "../../Assets/Products/Green tea mask.png";
import HairRemover from "../../Assets/Products/Ecrin hair removel spray.jpg";
import Lipstick from "../../Assets/Products/5 in 1 lipsticks.webp";
import WomenTrimmer from "../../Assets/Products/2 in 1 women trimmer.jpg";
import MiniWashingMachineImage from "../../Assets/Products/mini washing machine.jpg";


import MiniBodyMassagerImage from "../../Assets/Products/mini body messager.jpg";
import MiniJuicerBlenderImage from "../../Assets/Products/mini juicer.jpg";
import MiniSewingMachineImage from "../../Assets/Products/mini sewing.jpg";
import PowerBank20000mAhImage from "../../Assets/Products/power bank.jpg";
import TemperatureWaterBottleImage from "../../Assets/Products/water bottle.jpg";
import BookTabImage from "../../Assets/Products/book and tabs.jpg";
import NightCreamImage from "../../Assets/Products/night cream.jpg";

const Gadgets = () => {
    const products = [
        {
            id: 1,
            name: "Mini Washing Machine",
            price: "2000",
            image: MiniWashingMachineImage,
        },
        {
            id: 2,
            name: "Mini Body Massager",
            price: "12000",
            image: MiniBodyMassagerImage,
        },
        {
            id: 3,
            name: "Mini Juicer Blender",
            price: "2200",
            image: MiniJuicerBlenderImage,
        },
        {
            id: 4,
            name: "Mini Sewing Machine",
            price: "2000",
            image: MiniSewingMachineImage,
        },
        {
            id: 5,
            name: "Power Bank 20000mAh",
            price: "3500",
            image: PowerBank20000mAhImage,
        },
        {
            id: 6,
            name: "Temperature Water Bottle",
            price: "1900",
            image: TemperatureWaterBottleImage,
        },
        {
            id: 7,
            name: "4 Book + 8.5 Inch Tab",
            price: "1600",
            image: BookTabImage,
        },
    ];
    return (
        <div className="container d-flex justify-content-center p-0 ">
            <div
                className="row justify-content-center align-items-center w-100"
                style={{
                    backgroundColor: "#f5f5f5",
                    paddingTop: "5px",
                }}
            >
                {products.map((item, id) => (
                    <div
                        key={id}
                        className="p-0 col-lg-3 col-md-3 col-sm-6 col-6 d-flex align-items-stretch "
                        onClick={() =>
                        (window.location.href = `/products/${item.name
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`)
                        }
                        style={{ cursor: "pointer" }}
                    >
                        <div className="item">
                            <img src={item.image} alt="product Img" />
                            <h5 style={{ textAlign: "center" }}>{item.name}</h5>
                            <p className="price">RS: {item.price}/-</p>
                            <button>
                                <a href="https://wa.me/923139520225">
                                    <i
                                        style={{ fontSize: "25px" }}
                                        class="fa-brands fa-whatsapp"
                                    ></i>
                                </a>
                                <a href="https://wa.me/923139520225">Order Now</a>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Gadgets