import React, { useState } from "react";
import "./NavBar.css"; // Assuming you move your CSS to a separate file
import Logo from "../../Assets/logo.png";

const Navbar = () => {
  const [isNavActive, setIsNavActive] = useState(false);

  return (
    <>
      <div className="navhead">
        {/* <div className="port">AR Beauty Store</div> */}
        <div className="port">
          <a href="/">
            <img src={Logo} alt="logo" />
          </a>
        </div>
        <div className="hamburger">
          <i
            className="fas fa-bars nav-icon"
            id="nav-btn"
            style={{ color: "#000" }}
            onClick={() => setIsNavActive(!isNavActive)}
          ></i>
        </div>
        <nav className={`nav-list ${isNavActive ? "active" : ""}`}>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="#introduction">Shop</a>
            </li>
            <li>
              <a href="#skills">Testimonial</a>
            </li>
            <li>
              <a href="#skills">About Us</a>
            </li>
            <li>
              <a href="#skills">Contact US</a>
            </li>

            <li>
              <a className="mb-con" href="https://wa.me/923139520225">
                Let's Talk
              </a>
            </li>
          </ul>
        </nav>
        <div className="button">
          <button className="contact-btn">
            <a href="https://wa.me/923139520225">Let's Talk</a>
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
